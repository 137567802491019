.proj-heading {
  margin-top: 20vh;
  color: #ffffff;
  font-family: "Roboto_Mono";
  font-size: 5rem;
  margin-bottom: 15vh;
  text-align: center;
}
.hor-scroll {
  width: 90vw;
}
.project-img {
  height: auto;
  width: 30vw;
}
.Projects-main {
  height: auto;
  width: 95vw;
}
.project-container {
  display: flex;
  align-content: center;
  width: 90vw;
  height: 250vh;
  flex-direction: column;
  flex-wrap: wrap;
}
.card-title {
  font-size: 1.6rem;
  color: black;
  font-family: "Roboto_Mono";
}
h2 {
  color: black;
  font-size: 1rem;
  font-family: "Roboto_Mono";
}

ul {
  margin-bottom: 100px;
}
.project-box {
  background-color: rgba(255, 255, 255, 0.593);
  height: fit-content;
  border-radius: 5px;
  padding: 15px 15px;
  margin: 20px 20px;
  width: 40%;
}
@media only screen and (min-width: 1500px) {
  .Projects-main {
    align-items: center;
  }
  .project-container {
    display: flex;
    align-content: center;
    width: 90vw;
    height: fit-content;
  }
}
