* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  background-color: #05032b;
  height: 100%;  
  width: 100%;  
}
@font-face {
  font-family: "Roboto_Mono";
  src: url("./Roboto_Mono/RobotoMono-VariableFont_wght.ttf") format("truetype");
}
.name {
  margin-top: 33vh;
  color: white;
  font-size: 9rem;
  font-family: "Roboto_Mono";
  z-index: 0;
}

.content-header {
  color: white;
  font-family: "Roboto_Mono";
}

.Parallax {
  display: flex;
  justify-content: center;

  &__background,
  &__backgroundtwo,
  &__backgroundcircles,
  &__backgroundtrans {
    width: 100vw;
    height: 1280px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    pointer-events: none;
  }

  &__background {
    background-image: url("front.png");
    z-index: 2;
    top: 35vh;
  }

  &__backgroundtwo {
    z-index: 1;
    background-image: url("back.png");
    top: 45vh;
  }

  &__backgroundcircles {
    background-image: url("circles.png");
    z-index: 3;
    top: 35vh;
  }
  &__backgroundtrans {
    z-index: 0;
    top: 200vh;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
}

.content {
  margin-top: 60vh;
  color: white;
  font-family: "Roboto_Mono";
}

.project-types {
  font-size: 3rem;
  color: white;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 100px;
}
span {
  margin: 10px;
}

.type {
  width: 15vw;
}
.about-section {
  background-color: rgb(255, 255, 255);
}

.spanish-essay-link-home {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100; 
  padding: 8px; 
  border-radius: 4px;
  text-decoration: none; 
  color: white;
  font-family: "Roboto_Mono";
}