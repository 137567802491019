.main-essay-container {
    background-color: #f4f4f9; 
    width: 100%;
    min-height: 100%;
    box-sizing: border-box; 
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px; 
}

.essay-container {
    font-family: 'Lato', sans-serif;
    line-height: 1.6;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f9;
    position: relative; 
    display: flex;
    flex-direction: column; /* stacks children vertically */
    align-items: center; /* centers children horizontally */
    justify-content: flex-start;

}

.essay-image {
    max-width: 50%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.essay-doc-image {
    height: max-content;
    border-radius: 100px;
}

.essay-title {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #4b515d;
}

.essay-container p {
    margin-bottom: 20px;
    font-size: 1em;
    color: #4b515d;
}

.video-container {
    margin: 40px 0;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-top: 20px;
}

.grid-item {
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
}

.grid-item img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
}

.grid-item img:hover {
    transform: scale(1.05);
}

.grid-item figcaption {
    margin-top: 10px;
    color: #888;
    font-size: 0.9em;
}

.spanish-essay-link {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100; 
    padding: 8px; 
    border-radius: 4px;
    text-decoration: none; 
    font-family: "Roboto_Mono";
    color: black;
}

.spanish-essay-link:hover {
    text-decoration: underline; 
}

