.about-div {
  background-repeat: no-repeat;
  background-size: contain;
  width: 100vw;
  height: 100vh;
  background-image: url("About.png");
  top: 35vh;
  color: #060346;
  font-family: "Roboto_Mono";
}
img {
  height: 400px;
}
.bio {
  width: 35vw;
  position: absolute;
  margin-left: 10vw;
}

.bio-name {
  font-size: 3rem;
  margin-top: 0px;
  margin-bottom: 15px;
}

.bio-desc {
  font-size: 1rem;
}

.about-heading {
  color: #060346;
  font-family: "Roboto_Mono";
  font-size: 5rem;
  text-align: center;
}
.content-header {
  font-size: 1.5rem;
}

a {
  text-decoration: none;
  color: #060346;
  font-size: 1rem;
}
