.wordcloud-key {
  display: flex;
  justify-content: center;
  font-family: "Roboto_Mono";
  margin-bottom: 5vh;
}
.wordcloud-div {
  padding-bottom: 100px;
  height: fit-content;
}

ul.wordcloud {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: 2.5rem;
  font-family: "Roboto_Mono";
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  height: 500px;
}

ul.wordcloud li {
  color: white;
  display: block;
  font-size: 1.5rem;
  padding: 0.125rem 0.25rem;
  text-decoration: none;
  position: relative;
  margin: 5px 0;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  font-size: 1rem;
  width: fit-content;
  color: black;
  background-color: rgba(255, 255, 255, 0.801);
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.span-container {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.heading {
  margin-top: 45vh;
  color: #05032b;
  font-family: "Roboto_Mono";
  font-size: 5rem;
  margin-bottom: 5vh;
  text-align: center;
}

.wordcloud-container {
  background-color: rgb(255, 255, 255);
  width: 100vw;
}

ul {
  vertical-align: top;
  padding-left: 0;
  margin: 0 15px;
  text-align: center;
}
li {
  list-style: none;
}

.org-wc {
  text-align: center;
  justify-content: center;
  font-family: "Roboto_Mono";
  height: 90vh;
  margin-bottom: 5vh;
}
.list-container {
  width: 100vw;
  display: block;
  height: fit-content;
  padding-bottom: 50px;
}

.organize-item {
  margin-top: 10px;
  font-size: 1rem;
}

.cat-label {
  font-size: 1.5rem;
  margin: 0 4vw;
}

.org-div {
  display: inline-block;
  vertical-align: top;
}

.wc-btn {
  font-family: "Roboto_Mono";
  color: black;
  margin-left: 5vw;
  padding: 1rem;
  cursor: pointer;
}
